import { Box } from '@mui/material'
import LoginForm from '~/src/components/auth/login/LoginForm'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const LoginContainer = () => {
  const { t } = useTranslations()

  return (
    <>
      <Box>
        <Title variant='h3'>
          {t('auth.logIn')}
        </Title>
        <Title variant='h6'>
          {t('auth.logInToFinalizeYourOrder')}
        </Title>
      </Box>
      <LoginForm />
    </>
  )
}

export default LoginContainer
